import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_USER} from "./graphql/queries";

interface AuthContextProps {
    isLoggedIn: boolean | null;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
    currentUser: any,
    setCurrentUser: React.Dispatch<React.SetStateAction<any | null>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [getUser] = useLazyQuery(GET_USER);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getUser().then((resp: any) => {
                    if (resp && resp.data && resp.data.getUser) {
                        setIsLoggedIn(true);
                        setCurrentUser(resp.data.getUser);
                    } else setIsLoggedIn(false);
                }
            )
        } else {
            setIsLoggedIn(false);
        }

    }, []);


    return (
        <AuthContext.Provider value={{isLoggedIn, setIsLoggedIn: setIsLoggedIn, currentUser, setCurrentUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
