import {useTranslation} from "react-i18next";

function LanguageSwitcher() {
    const { i18n } = useTranslation()

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    }

    return (
        <div className="pt-4 pl-4">
            <button onClick={() => changeLanguage('en-US')}>English</button>
            <button onClick={() => changeLanguage('zh-CN')} className="ml-4">中文</button>
        </div>
    )
}

export default LanguageSwitcher