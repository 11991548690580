import {gql} from '@apollo/client';

export const REGISTER = gql`
  mutation Register($registerInput: RegisterInput!) {
    register(registerInput: $registerInput) {
      id
      username
      email
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      user {
        id
        username
        email
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      username
      email
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;

export const ADD_IDEA = gql`
    mutation AddIdea($ideaInput: IdeaInput!) {
        addIdea(ideaInput: $ideaInput) {
            id
            title
            abstract
    }
}
`;

export const REMOVE_IDEA = gql`
  mutation RemoveIdea($id: String!) {
    removeIdea(id: $id)
  }
`;

export const ADD_WORK = gql`
    mutation AddWork($workInput: WorkInput!) {
        addWork(workInput: $workInput) {
            id
            title
            abstract
            numOfEpisodes
            user {
                id
            }
    }
}
`;

export const UPDATE_WORK = gql`
  mutation UpdateWork($updateWorkInput: UpdateWorkInput!) {
    updateWork(updateWorkInput: $updateWorkInput) {
      id
      title
      abstract
      numOfEpisodes
    }
  }
`;

export const REMOVE_WORK = gql`
  mutation RemoveWork($id: String!) {
    removeWork(id: $id) {
      id
    }
  }
`;

export const GENERATE_ABSTRACT = gql`
  mutation GenerateAbstract($workId: String!) {
    generateAbstract(workId: $workId) {
      id
      title
      abstract
      numOfEpisodes
    }
  }
`;

export const ADD_ROLES = gql`
    mutation AddRoles($roles: [RoleInput!]!) {
        addRoles(roles: $roles) {
            id
            name
            work {
                id
            }
            description
        }
    }
`;

export const CLEAR_ROLES = gql`
    mutation ClearRoles($clearRolesInput: ClearRolesInput!) {
        clearRoles(clearRolesInput: $clearRolesInput)
    }
`;

export const GENERATE_ROLES = gql`
  mutation GenerateRoles($workId: String!) {
    generateRoles(workId: $workId) {
      id
      name
      description
    }
  }
`;

export const ADD_EPISODE = gql`
    mutation AddEpisode($episodeInput: EpisodeInput!) {
        addEpisode(episodeInput: $episodeInput) {
            id
        }
    }
`;

export const UPDATE_EPISODE = gql`
  mutation UpdateEpisode($updateEpisodeInput: UpdateEpisodeInput!) {
    updateEpisode(updateEpisodeInput: $updateEpisodeInput) {
      id
      title
      order
    }
  }
`;

export const LIST_EPISODES = gql`
    query ListEpisodes($workId: String!) {
        listEpisodes(workId: $workId) {
            id
            title
            order
        }
    }
`;

export const REORDER_EPISODES = gql`
  mutation ReorderEpisodes($episodeIds: [String!]!) {
    reorderEpisodes(episodeIds: $episodeIds) {
      id
      title
      order
    }
  }
`;

export const GENERATE_EPISODES = gql`
  mutation GenerateEpisodes($workId: String!) {
    generateEpisodes(workId: $workId) {
      id
      title
      order
    }
  }
`;

export const REMOVE_EPISODE = gql`
  mutation RemoveEpisode($id: String!) {
    removeEpisode(id: $id)
  }
`;

export const ADD_SCENE = gql`
    mutation AddScene($sceneInput: SceneInput!) {
        addScene(sceneInput: $sceneInput) {
            id
            title
        }
    }
`;

export const UPDATE_SCENE = gql`
  mutation UpdateScene($updateSceneInput: UpdateSceneInput!) {
    updateScene(updateSceneInput: $updateSceneInput) {
      id
      title
      content
      roleDescription
      conversations
      order
    }
  }
`;

export const REORDER_SCENES = gql`
  mutation ReorderScenes($sceneIds: [String!]!) {
    reorderScenes(sceneIds: $sceneIds) {
      id
      title
      order
    }
  }
`;

export const GENERATE_SCENES = gql`
  mutation GenerateScenes($episodeId: String!) {
    generateScenes(episodeId: $episodeId) {
      id
      title
      order
    }
  }
`;

export const GENERATE_SCENE = gql`
  mutation GenerateScene($sceneId: String!) {
    generateScene(sceneId: $sceneId) {
      id
      title
      content
      roleDescription
      conversations
      order
    }
  }
`;

export const COMPLETE_SCENE_CONTENT = gql`
  mutation CompleteSceneContent($sceneId: String!, $content: String!, $cursor: Float!) {
    completeSceneContent(sceneId: $sceneId, content: $content, cursor: $cursor)
  }
`;

export const COMPLETE_SCENE_CONVERSATIONS = gql`
  mutation completeSceneConversations($sceneId: String!, $conversations: String!, $cursor: Float!) {
    completeSceneConversations(sceneId: $sceneId, conversations: $conversations, cursor: $cursor)
  }
`;

export const REMOVE_SCENE = gql`
  mutation RemoveScene($id: String!) {
    removeScene(id: $id)
  }
`;

