import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en-US/translation.json';
import zhTranslation from './locales/zh-CN/translation.json';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en-US': {
                translation: enTranslation,
            },
            'zh-CN': {
                translation: zhTranslation,
            },
        },
        lng: localStorage.getItem('i18nextLng') || 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
