// Function to create initials from first and last name
import {useEffect, useState} from "react";

const getInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
};

const UserAvatar = (props: any) => {
    const [user, setUser] = useState<any | null>(props.user);
    const sizeClass = props.mode == "thumbnail" ? "w-8 h-8" : "w-32 h-32";
    const fontSize = props.mode == "thumbnail" ? "p-2" : "text-4xl";

    useEffect(() => {
        if (props.user)
            setUser(user);
    }, [props.user]);

    return (
        <>
            {user.avatarUrl ?
                <img src={user.avatarUrl} className={`${sizeClass} rounded-full`}/>
                :
                <div
                    className={`${sizeClass} rounded-full bg-gray-200 flex items-center justify-center  ${fontSize} font-semibold`}>
                    {getInitials(user.firstName, '')}
                </div>
            }
        </>
    );
}

export default UserAvatar;
