import React from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from "@apollo/client";
import {LIST_WORKS} from '../../graphql/queries';
import Menu from "../../components/Menu";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import {Link} from 'react-router-dom';
import {FaEdit, FaPlus} from "react-icons/fa";

function Works() {
    const {t} = useTranslation('', {keyPrefix: 'construction'});

    return (
        <>
            <div className="flex items-center mb-4">
                <h1 className="text-4xl font-bold">{t('constructionTitle')}</h1>
            </div>
        </>
    );
}

export default Works;