import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';
import {REGISTER} from '../graphql/mutations';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";

function Register() {
    const {t} = useTranslation('', {keyPrefix: 'register'});
    const navigate = useNavigate();
    const [register, {error}] = useMutation(REGISTER);

    const formik = useFormik({
        initialValues: {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            inviteCode: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().required(t('usernameRequired')),
            firstName: Yup.string().required(t('firstNameRequired')),
            lastName: Yup.string().required(t('lastNameRequired')),
            email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
            password: Yup.string().min(6, t('passwordMin')).required(t('passwordRequired')),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), ''], t('passwordsMustMatch'))
                .required(t('confirmPasswordRequired')),
            inviteCode: Yup.string()
        }),
        onSubmit: async (values: any) => {
            try {
                await register({
                    variables: {
                        registerInput: {
                            username: values.username,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                            inviteCode: values.inviteCode || null
                        }
                    }
                });
                navigate('/login');
            } catch (err) {
                console.error('Registration error:', err);
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-teal1">
            <div className="w-full max-w-md">
                <div className="mb-8 mt-4">
                    <img src="/images/logo.png" alt="Logo" className="mx-auto mb-4 w-24"/>
                </div>
                <div className="bg-white rounded shadow-md p-8">
                    <h2 className="text-2xl font-bold mb-8 text-center text-teal2">{t('registerTitle')}</h2>
                    <div className="flex justify-between">
                        <div/>
                        <LanguageSwitcher />
                    </div>
                    {error && <div className="bg-red-500 text-white p-3 rounded mb-4">{error.message}</div>}
                    <form onSubmit={formik.handleSubmit}>
                        {/* Username Field */}
                        <div className="mb-4">
                            <label htmlFor="username" className="block text-gray-700 mb-2">{t('username')}</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                            />
                            {formik.touched.username && formik.errors.username && (
                                <div className="text-red-500 text-sm">{formik.errors.username}</div>
                            )}
                        </div>

                        {/* First Name Field */}
                        <div className="mb-4">
                            <label htmlFor="firstName" className="block text-gray-700 mb-2">{t('firstName')}</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                            {formik.touched.firstName && formik.errors.firstName && (
                                <div className="text-red-500 text-sm">{formik.errors.firstName}</div>
                            )}
                        </div>

                        {/* Last Name Field */}
                        <div className="mb-4">
                            <label htmlFor="lastName" className="block text-gray-700 mb-2">{t('lastName')}</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                            {formik.touched.lastName && formik.errors.lastName && (
                                <div className="text-red-500 text-sm">{formik.errors.lastName}</div>
                            )}
                        </div>

                        {/* Email Field */}
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 mb-2">{t('email')}</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className="text-red-500 text-sm">{formik.errors.email}</div>
                            )}
                        </div>

                        {/* Password Field */}
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 mb-2">{t('password')}</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className="text-red-500 text-sm">{formik.errors.password}</div>
                            )}
                        </div>

                        {/* Confirm Password Field */}
                        <div className="mb-4">
                            <label htmlFor="confirmPassword"
                                   className="block text-gray-700 mb-2">{t('confirmPassword')}</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className="text-red-500 text-sm">{formik.errors.confirmPassword}</div>
                            )}
                        </div>

                        {/* Invite Code Field (Optional) */}
                        <div className="mb-4">
                            <label htmlFor="inviteCode" className="block text-gray-700 mb-2">{t('inviteCode')}</label>
                            <input
                                type="text"
                                id="inviteCode"
                                name="inviteCode"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.inviteCode}
                            />
                            {formik.touched.inviteCode && formik.errors.inviteCode && (
                                <div className="text-red-500 text-sm">{formik.errors.inviteCode}</div>
                            )}
                        </div>

                        {/* Submit Button */}
                        <div className="mb-4">
                            <button type="submit"
                                    className="w-full py-2 px-4 bg-gold text-white rounded hover:bg-gold-darker">
                                {t('registerButton')}
                            </button>
                        </div>
                    </form>
                    <div className="text-center">
                        <Link to="/login" className="text-blue-500">
                            {t('haveAccount')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
