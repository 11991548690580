import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, InMemoryCache, ApolloProvider, DefaultOptions, HttpLink} from "@apollo/client";
import './i18n';
import {AuthProvider} from './AuthContext';
import {setContext} from "@apollo/client/link/context";


const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_HOST,
});

// const defaultOptions: DefaultOptions = {
//     watchQuery: {
//         fetchPolicy: 'no-cache',
//         errorPolicy: 'ignore',
//     },
//     query: {
//         fetchPolicy: 'network-only',
//         errorPolicy: 'all',
//     },
// }

const authLink = setContext(async (_, {headers}) => {
    const token = localStorage.getItem('token') ?? null;
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    //defaultOptions: defaultOptions,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ApolloProvider client={client}>
        <React.StrictMode>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </React.StrictMode>
    </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
