import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../graphql/mutations';
import { useAuth } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import LanguageSwitcher from "../components/LanguageSwitcher";
import { useTranslation } from 'react-i18next';

function Login() {
    const { setIsLoggedIn, setCurrentUser } = useAuth();
    const { t } = useTranslation('', { keyPrefix: 'login' });
    const navigate = useNavigate();
    const [login, { error: loginError }] = useMutation(LOGIN);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t('invalidEmail')).required(t('emailRequired')),
            password: Yup.string().required(t('passwordRequired'))
        }),
        onSubmit: async (values) => {
            try {
                const response = await login({ variables: { email: values.email, password: values.password } });
                localStorage.setItem('token', response.data.login.accessToken); // Assuming the token is returned in the login response
                setIsLoggedIn(true);
                setCurrentUser(response.data.login.user);
                navigate('/home');
            } catch (err) {
                console.error('Login error:', err);
            }
        },
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-teal1">
            <div className="w-full max-w-md">
                <div className="mb-8 mt-4">
                    <img src="/images/logo.png" alt="Logo" className="mx-auto mb-4 w-24"/>
                </div>
                <div className="bg-white rounded shadow-md p-8">
                    <h2 className="text-2xl font-bold mb-6 text-center text-teal2">{t('title')}</h2>
                    <div className="flex justify-between">
                        <div/>
                        <LanguageSwitcher />
                    </div>
                    {loginError && <div className="bg-red-500 text-white p-3 rounded mb-4">{loginError.message}</div>}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="email">
                                {t('email')}
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                placeholder={t('emailPlaceholder')}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className="text-red-500 text-sm">{formik.errors.email}</div>
                            )}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 mb-2" htmlFor="password">
                                {t('password')}
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                className="w-full px-3 py-2 border rounded shadow-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                placeholder={t('passwordPlaceholder')}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className="text-red-500 text-sm">{formik.errors.password}</div>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-gold text-white rounded hover:bg-gold-darker"
                        >
                            {t('submitButton')}
                        </button>
                        <div className="text-center mt-4">
                            <Link to="/register" className="text-blue-500">
                                {t('newAccount')}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
