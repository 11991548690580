import React, {useEffect, useState} from "react";
import {FaCheck} from "react-icons/fa";
import {useMutation, useQuery} from "@apollo/client";
import {
    ADD_EPISODE,
    ADD_SCENE,
    GENERATE_EPISODES,
    GENERATE_SCENES,
    REMOVE_EPISODE,
    REORDER_EPISODES,
    REORDER_SCENES
} from "../../../graphql/mutations";
import {LIST_SCENES} from "../../../graphql/queries";
import {useTranslation} from "react-i18next";
import {faEye, faFireFlameCurved, faPlus, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import {Link} from "react-router-dom";

function StructureList(props: any) {
    const {t} = useTranslation('', {keyPrefix: 'edit.structureList'});
    const work = props.work;
    const episodeData = props.episodes;
    const selectedScene = props.scene;
    const refetchEpisodes = props.refetchEpisodes;
    const [episodes, setEpisodes] = useState(Array());
    const [addEpisode] = useMutation(ADD_EPISODE);
    const [newEpisodeTitle, setNewEpisodeTitle] = useState('');
    // State to manage whether the episode input is visible
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [newSceneTitle, setNewSceneTitle] = useState('');
    const [isSceneInputVisible, setIsSceneInputVisible] = useState(false);
    const [selectedEpisodeId, setSelectedEpisodeId] = useState('');
    const [selectedEpisodeScenes, setSelectedEpisodeScenes] = useState(Array());

    useEffect(() => {
        setEpisodes(episodeData);
    }, [episodeData]);

    useEffect(() => {
        if (selectedScene)
            setSelectedEpisodeId(selectedScene.episode.id);
    }, [selectedScene]);

    const handleAddEpisode = async () => {
        try {
            // Determine the next order number
            const nextOrder = episodes
                ? episodes.length + 1
                : 1;

            const {data} = await addEpisode({
                variables: {
                    episodeInput: {
                        title: newEpisodeTitle,
                        workId: work.id,
                        order: nextOrder,
                    },
                },
            });
            //setIsInputVisible(false);
            setNewEpisodeTitle('');
            // Refetch episodes after adding a new one
            refetchEpisodes().then((resp: any) => {
                setEpisodes(resp.data.listEpisodes);
            });
        } catch (err) {
            console.error('Error adding episode:', err);
        }
    };

    const [addScene] = useMutation(ADD_SCENE);

    const {data: scenesData, refetch: refetchScenes} = useQuery(LIST_SCENES, {
        variables: {episodeId: selectedEpisodeId},
        skip: !selectedEpisodeId,
        onCompleted: () => {
            setSelectedEpisodeScenes(scenesData.listScenes);
        },
    });

    const handleAddScene = async () => {
        try {
            // Calculate the next order for the new scene
            const nextOrder = scenesData?.listScenes?.length ? scenesData.listScenes.length + 1 : 1;
            await addScene({
                variables: {
                    sceneInput: {
                        title: newSceneTitle,
                        episodeId: selectedEpisodeId,
                        order: nextOrder,
                        workId: work.id
                    }
                }
            });
            //setIsSceneInputVisible(false);
            setNewSceneTitle('');
            refetchScenes().then((resp) => {
                setSelectedEpisodeScenes(resp.data.listScenes);
            });
        } catch (err) {
            console.error('Error adding scene:', err);
        }
    };

    const showSceneInput = (episodeId: string) => {
        setIsInputVisible(false);
        setSelectedEpisodeId(episodeId);
        setIsSceneInputVisible(true);
    };

    const [generateEpisodes, {
        loading: generateEpisodesLoading,
        error: generateEpisodesError
    }] = useMutation(GENERATE_EPISODES);
    const [generateScenes, {loading: generateScenesLoading, error: generateScenesError}] = useMutation(GENERATE_SCENES);

    const [reorderEpisodes] = useMutation(REORDER_EPISODES);

    const onDragEndEpisodes = async (result: any) => {
        if (!result.destination) return;
        const reorderedEpisodes: any[] = Array.from(episodes);
        const [removed] = reorderedEpisodes.splice(result.source.index, 1);
        reorderedEpisodes.splice(result.destination.index, 0, removed);

        const episodeIds = reorderedEpisodes.map((episode: any) => episode.id);
        setEpisodes(reorderedEpisodes);
        reorderEpisodes({variables: {episodeIds}});
    };

    const [reorderScenes] = useMutation(REORDER_SCENES);

    const onDragEndScenes = async (result: any) => {
        if (!result.destination) return;
        const reorderedScenes: any[] = Array.from(scenesData.listScenes);
        const [removed] = reorderedScenes.splice(result.source.index, 1);
        reorderedScenes.splice(result.destination.index, 0, removed);

        const sceneIds = reorderedScenes.map((scene: any) => scene.id);
        setSelectedEpisodeScenes(reorderedScenes);
        // update scene state
        reorderScenes({variables: {sceneIds}}).then(() => {
            refetchScenes().then((resp: any) => {
                setSelectedEpisodeScenes(resp.data.listScenes);
            });
        });
    };

    const [removeEpisode] = useMutation(REMOVE_EPISODE);

    const handleRemoveEpisode = async (episodeId: string) => {
        // Prompt the user for confirmation
        const isConfirmed = window.confirm(t('removeEpisodeConfirmation'));
        if (isConfirmed) {
            try {
                // Call the removeEpisode mutation
                await removeEpisode({variables: {id: episodeId}});
                refetchEpisodes().then((resp: any) => {
                    setEpisodes(resp.data.listEpisodes);
                });
            } catch (error) {
                console.error("Error removing episode:", error);
            }
        }
    };

    const generateEpisodesHandler = async () => {
        try {
            const response = await generateEpisodes({variables: {workId: work.id}});
            if (response.data && response.data.generateEpisodes) {
                refetchEpisodes().then((resp: any) => {
                    setEpisodes(resp.data.listEpisodes);
                });
            }
        } catch (error) {
            console.error("Error generating episodes:", error);
        }
    }

    const generateScenesHandler = async (episodeId: string) => {
        try {
            const response = await generateScenes({variables: {episodeId: episodeId}});
            if (response.data && response.data.generateScenes) {
                refetchScenes().then((resp) => {
                    setSelectedEpisodeScenes(resp.data.listScenes);
                });
            }
        } catch
            (error) {
            console.error("Error generating scenes:", error);
        }
    }

    return (
        <>
            {work && (
                <div className="bg-gray-100 p-4 rounded-md">
                    <div className="flex justify-between">
                        <h2 className="font-bold text-2xl">
                            <Link to={`/edit/${work.id}`}>
                                {work.title}
                            </Link>
                        </h2>
                        <div>
                            <button
                                type="button"
                                onClick={generateEpisodesHandler}
                                className="inline-block text-xl rounded-md text-green-400"
                            >
                                <FontAwesomeIcon
                                    icon={!generateEpisodesLoading ? faFireFlameCurved : faSpinner}
                                    className={!generateEpisodesLoading ? '' : 'animate-spin'}
                                />
                            </button>
                            <button
                                className="inline-block ml-2 text-xl rounded-md text-green-400"
                                onClick={() => {
                                    setIsInputVisible(true);
                                    setNewEpisodeTitle('');
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                            <Link to={`/export/${work.id}`} target="_blank"
                                  className="inline-block ml-2 text-xl rounded-md text-green-400">
                                <FontAwesomeIcon icon={faEye}/>
                            </Link>
                        </div>
                    </div>
                    {/* Display episodes if available */}
                    {episodes && episodes.length > 0 && (
                        <DragDropContext onDragEnd={onDragEndEpisodes}>
                            <Droppable droppableId="episodes">
                                {(provided) => (
                                    <ul className="mt-4 pl-5 text-xl list-decimal" {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {episodes.map((episode: any, index: number) => (
                                            <Draggable key={episode.id} draggableId={episode.id} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                    >
                                                        <div className="flex justify-between items-center">
                                  <span
                                      className={`cursor-pointer ${selectedEpisodeId === episode.id ? 'text-blue-500 font-bold' : ''}`}
                                      onClick={() => {
                                          setSelectedEpisodeId(
                                              selectedEpisodeId === episode.id ? '' : episode.id
                                          );
                                      }}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                  >
                                    {episode.title}
                                  </span>
                                                            {selectedEpisodeId === episode.id && (
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            generateScenesHandler(episode.id);
                                                                        }}
                                                                        className="p-1 rounded hover:bg-gray-200"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={!generateScenesLoading ? faFireFlameCurved : faSpinner}
                                                                            className={!generateScenesLoading ? '' : 'animate-spin'}
                                                                        />
                                                                    </button>
                                                                    <button
                                                                        onClick={() => showSceneInput(episode.id)}
                                                                        className="p-1 rounded hover:bg-gray-200"
                                                                        aria-label="Add Scene"
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus}/>
                                                                    </button>
                                                                    {/*<button*/}
                                                                    {/*    //onClick={() => showSceneEdit(episode.id)}*/}
                                                                    {/*    className="p-1 rounded hover:bg-gray-200"*/}
                                                                    {/*    aria-label="Edit"*/}
                                                                    {/*>*/}
                                                                    {/*    <FontAwesomeIcon icon={faEdit}/>*/}
                                                                    {/*</button>*/}
                                                                    <button
                                                                        onClick={() => handleRemoveEpisode(episode.id)}
                                                                        className="p-1 rounded hover:bg-gray-200"
                                                                        aria-label="Delete"
                                                                    >
                                                                        <FontAwesomeIcon icon={faTimes}/>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {isSceneInputVisible && selectedEpisodeId === episode.id && (
                                                            <div className="relative mt-2">
                                                                <input type="text"
                                                                       value={newSceneTitle}
                                                                       onChange={(e) => setNewSceneTitle(e.target.value)}
                                                                       className="p-2 w-full border rounded-md"
                                                                       placeholder={t('newSceneTitle')}/>
                                                                <button
                                                                    onClick={handleAddScene}
                                                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 rounded-md bg-green-500 text-white"
                                                                >
                                                                    <FaCheck/>
                                                                </button>
                                                            </div>
                                                        )}
                                                        {selectedEpisodeId === episode.id && selectedEpisodeScenes && selectedEpisodeScenes.length > 0 && (
                                                            <DragDropContext onDragEnd={onDragEndScenes}>
                                                                <Droppable droppableId="scenes">
                                                                    {(provided) => (
                                                                        <ul className="mt-2 pl-10 list-disc" {...provided.droppableProps}
                                                                            ref={provided.innerRef}>
                                                                            {selectedEpisodeScenes.map((scene: any, index: number) => (
                                                                                <Draggable key={scene.id}
                                                                                           draggableId={scene.id}
                                                                                           index={index}>
                                                                                    {(provided) => (
                                                                                        <li key={scene.id}
                                                                                            className="cursor-pointer"
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <Link
                                                                                                className={`${selectedScene && scene.id === selectedScene.id ? 'font-bold' : ''}`}
                                                                                                to={`/edit/${work.id}/${scene.id}`}>
                                                                                                {scene.title}
                                                                                            </Link>
                                                                                        </li>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </ul>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        )}
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}

                    {/* Conditionally render episode input based on episodes length and isInputVisible */}
                    {isInputVisible || (!episodes || episodes.length === 0) ? (
                        <div className="relative mt-1">
                            <input
                                type="text"
                                id="newEpisodeTitle"
                                name="newEpisodeTitle"
                                value={newEpisodeTitle}
                                onChange={(e) => setNewEpisodeTitle(e.target.value)}
                                placeholder={t('newEpisodeTitle')}
                                className="p-2 w-full border rounded-md"
                            />
                            <button
                                onClick={handleAddEpisode}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 rounded-md bg-green-500 text-white"
                            >
                                <FaCheck/>
                            </button>
                        </div>
                    ) : ""}
                </div>
            )}
        </>
    )
        ;
}

export default StructureList;

